import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';


interface OptionInterface {
  label: string;
  translatedLabel?: string;
  value: string;
  disqualify?: boolean
}

export const useOptionsTranslation = () => {
  const { t } = useTranslation();

  const renderOptionsWithTranslate = useCallback(({
    options,
    optionKey = 'label',
    prefix,
    useTranslatedLabel
  }: {
    options: OptionInterface[],
    optionKey?: string,
    prefix?: string,
    useTranslatedLabel?: boolean
  }) => {
    return options.map((option: any) => {

      let translationKey = option[optionKey]

      if (prefix) {
        translationKey = `${prefix}.${option[optionKey]}`
      }

      if (option['value'].startsWith('customField') || useTranslatedLabel) {
        return { ...option, label: option.translatedLabel || option.label }
      }

      return { ...option, label: t(translationKey) }
    })
  }, [t]);

  return {
    renderOptionsWithTranslate
  };
};
